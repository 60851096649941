import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Card, createStyles, Theme, CircularProgress } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        header: {
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(1),
            },
        }
    })
)

interface IProps {
    name: string,
    data: React.ReactNode,
    loading: boolean
}

const RestaurantImageDay: React.FC<IProps> = props => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardContent>
                <div className={classes.header}>
                    <Avatar>{props.name.charAt(0)}</Avatar>

                    {props.name}
                </div>
                <div className="content-custom">
                    {props.loading?(
                        <CircularProgress/>
                    ):props.data}
                </div>
            </CardContent>
        </Card>
    );
}

export default RestaurantImageDay