import React from 'react'
import { AppBar, Toolbar, CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import { IApplicationStore } from '../../../constants/store-interfaces';
import { makeStyles } from '@material-ui/core/styles';

interface IProps {

}

const useStyles = makeStyles(theme => ({
    loadingWrapper: {
        position: 'fixed',
        background: 'rgba(255,255,255,.8)',
        width: '100vw',
        height: 'calc( 100vh - 80px )',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0
    }
}));

const AppPageContainer:React.FC<IProps> = (props) => {
    const loading = useSelector((state: IApplicationStore) => state.loadingStore.loadingFood)
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar style={{width: "100%"}}>
                    <Typography variant="h6" color="inherit" noWrap style={{width: "100%"}}>
                        <div className="header-top">
                            <img src="/logo_biele.svg" style={{width: '200px', margin: "0"}} alt=""/>
                            <span>
                                Obedy {new Date().getFullYear()} | Dobrú chuť :)
                            </span>
                        </div>
                    </Typography>
                </Toolbar>
            </AppBar>
            <main>
                {(loading)?(
                    <div className={classes.loadingWrapper}>
                        <CircularProgress/>
                    </div>
                ):(null)}
                {props.children}
            </main>
        </React.Fragment>
    )
}

export default AppPageContainer