import { 
    SET_LOADING_FOOD,
} from "../constants/action-types"
import { Reducer } from 'redux'
import { ILoadingStore } from '../constants/store-interfaces';
import { SET_LOADING_SIKI, SET_LOADING_DOBRECHUTNE } from '../constants/action-types';

const initialState: ILoadingStore = {
    loadingFood: false,
    loadingSiki: false,
    loadingDobreChutne: false
}

const reducer: Reducer<ILoadingStore> = (state = initialState, action) => {
    let was = false

    switch(action.type) {
        //Set of loading
        case SET_LOADING_FOOD:
            state = {
                ...state,
                loadingFood: action.payload
            }
            was = true
            break
        //Set of loading
        case SET_LOADING_SIKI:
            state = {
                ...state,
                loadingSiki: action.payload
            }
            was = true
            break
        case SET_LOADING_DOBRECHUTNE: 
            state = {
                ...state,
                loadingDobreChutne: action.payload
            }
            was = true
            break
    }

    if(action.type.endsWith("_FAILED") && was) {
        //TODO show error
    }

    return state
}

export { reducer as loadingReducer }