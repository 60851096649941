export function handleErrors(response: any) {
    if(response.status  ===  404 || response.status === 409)  {
        return response
    }
    if (!response.ok) {
        if(response.status > 400 && response.status <= 500) {
            console.error('Request warning', response);
            
            throw Error(response.statusText);
        }

        console.error('Request error', response)
        throw Error(response.statusText);
    }
    return response;
}