import { SET_LOADING_SIKI, SET_LOADING_DOBRECHUTNE } from '../../constants/action-types';
import { 
    SET_LOADING_FOOD
} from '../../constants/action-types';


export function setLoadingFood(payload: boolean) {
    return { type: SET_LOADING_FOOD, payload }
}
export function setLoadingSiki(payload: boolean) {
    return { type: SET_LOADING_SIKI, payload }
}
export function setLoadingDobreChutne(payload: boolean) {
    return { type: SET_LOADING_DOBRECHUTNE, payload }
}