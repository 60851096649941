import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './js/App';
import store from './js/store/index';
import { Provider }from 'react-redux';
import { Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import ScrollToTop from './js/components/stateless/ScrollTop';

export const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
            <Router history={history}>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </Router>
    </Provider>, 
    document.getElementById('root')
)
