class StateLoader {

    loadState() {

        // return this.initializeState()
        try {
            let serializedState = localStorage.getItem("obedy:state");

            if (serializedState === null) {
                return this.initializeState();
            }

            let returnedState = JSON.parse(serializedState)

            return returnedState;
        }
        catch (err) {
            return this.initializeState();
        }
    }

    saveState(state: any) {
        try {
            let serializedState = JSON.stringify(state);
            localStorage.setItem("obedy:state", serializedState);

        }
        catch (err) {
        }
    }

    initializeState() {
        return {};
    }
}

export default StateLoader;