import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Card, createStyles, Theme, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { IRestaurant, Days } from '../../../constants/types';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        header: {
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            '& > *': {
                margin: theme.spacing(1),
            },
        }
    })
)

interface IProps {
    restaurant: IRestaurant,
    day: Days
}

const RestaurantDay: React.FC<IProps> = props => {
    const classes = useStyles();

    let day = props.restaurant.days.find(day => day.name === props.day)

    return (
        <Card className={classes.root}>
            <CardContent>
                <div className={classes.header}>
                    <Avatar>{props.restaurant.name.charAt(0)}</Avatar>

                    {props.restaurant.name}
                </div>
                <h4>Polievky</h4>
                {(typeof day !== 'undefined')?(
                    <Table>
                        <TableBody>
                            {(day.soaps.map((item, key) => (
                                <TableRow key={key}>
                                    <TableCell align="left">
                                        {item}
                                    </TableCell>
                                </TableRow>
                            )))}
                        </TableBody>
                    </Table>
                ):(null)}
                <h4>Hlavné jedlá</h4>
                {(typeof day !== 'undefined')?(
                    <Table>
                        <TableBody>
                            {(day.foods.map((item, key) => (
                                <TableRow key={key}>
                                    <TableCell align="left">
                                        {item}
                                    </TableCell>
                                </TableRow>
                            )))}
                        </TableBody>
                    </Table>
                ):(null)}
            </CardContent>
        </Card>
    );
}

export default RestaurantDay