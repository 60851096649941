import React, { useEffect } from 'react'
import { Switch, Route, RouteComponentProps, withRouter } from 'react-router'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

//Pages components
import MainPage from './pages/MainPage';
import AppPageContainer from './containers/AppPageContainer';
import { useDispatch } from 'react-redux';
import { loadFood, loadSikiFood, loadDobreChutneFood } from '../../actions/global/food';

interface IProps extends RouteComponentProps {}

const Main: React.FC<IProps> = props => {
    const location = props.location

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadFood())
        dispatch(loadSikiFood())
        dispatch(loadDobreChutneFood())
    }, [dispatch])

    return (
        <AppPageContainer>
            <TransitionGroup>
                <CSSTransition key={location.key} timeout={{ enter: 300, exit: 300 }} classNames={'fade300'}>
                    <Switch location={location}>
                        <Route
                            exact
                            path={['/']}
                            component={MainPage}
                        />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </AppPageContainer>
    )
}

export default withRouter(Main)
