import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { IApplicationStore } from '../constants/store-interfaces'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { globalReducer } from '../reducers/global'
import { loadingReducer } from '../reducers/loading'
import StateLoader from './stateLoader'

export const appRootReducer = combineReducers<IApplicationStore>({
    globalStore: globalReducer,
    loadingStore: loadingReducer,
})

export const rootReducer = (state: any, action: any) => {
    return appRootReducer(state, action)
}

const stateLoader = new StateLoader()

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, stateLoader.loadState(), composeEnhancers(applyMiddleware(promise, thunk)))

store.subscribe(() => {
    stateLoader.saveState(store.getState())
})

export default store
