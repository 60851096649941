import React, { Component } from 'react'
import './App.scss'

import Main from './components/app/Main'

export let i18n: any

interface IProps {}

class App extends Component<IProps> {
    render() {
        return (
            <Main />
        )
    }
}

export default App
