import useWindowSize from "./useWindowSize"

function useIsMobile() {
    const windowSize = useWindowSize()

    const isMobile = windowSize.width?((windowSize.width>1023)?false:true):false

    return isMobile
}

export default useIsMobile