export interface IDay {
    soaps: string[],
    foods: string[]
}

export enum Days {
    pondelok = "Pondelok",
    utorok = "Utorok",
    streda = "Streda",
    stvrtok = "Štvrtok",
    piatok = "Piatok"
}

export interface IRestaurant {
    name: string,
    days: {
        name: Days,
        soaps: string[],
        foods: string[]
    }[]
}